{
  "en": {
    "activerecord": {
      "attributes": {
        "user": {
          "confirmation_sent_at": "Confirmation sent at",
          "confirmation_token": "Confirmation token",
          "confirmed_at": "Confirmed at",
          "created_at": "Created at",
          "current_password": "Current password",
          "current_sign_in_at": "Current sign in at",
          "current_sign_in_ip": "Current sign in IP",
          "email": "Email",
          "encrypted_password": "Encrypted password",
          "failed_attempts": "Failed attempts",
          "last_sign_in_at": "Last sign in at",
          "last_sign_in_ip": "Last sign in IP",
          "locked_at": "Locked at",
          "password": "Password",
          "password_confirmation": "Password confirmation",
          "remember_created_at": "Remember created at",
          "remember_me": "Remember me",
          "reset_password_sent_at": "Reset password sent at",
          "reset_password_token": "Reset password token",
          "sign_in_count": "Sign in count",
          "unconfirmed_email": "Unconfirmed email",
          "unlock_token": "Unlock token",
          "updated_at": "Updated at"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "Validation failed: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Cannot delete record because dependent %{record} exist",
            "has_one": "Cannot delete record because a dependent %{record} exists"
          }
        }
      },
      "models": {
        "user": {
          "one": "User",
          "other": "Users"
        }
      }
    },
    "avo": {
      "action_ran_successfully": "Action ran successfully!",
      "actions": "Actions",
      "add_filter": "Add filter",
      "and_x_other_resources": "and %{count} other resources",
      "applied": "applied",
      "are_you_sure": "Are you sure?",
      "are_you_sure_detach_item": "Are you sure you want to detach this %{item}.",
      "are_you_sure_you_want_to_run_this_option": "Are you sure you want to run this action?",
      "attach": "Attach",
      "attach_and_attach_another": "Attach & Attach another",
      "attach_item": "Attach %{item}",
      "attachment_class_attached": "%{attachment_class} attached.",
      "attachment_class_detached": "%{attachment_class} detached.",
      "attachment_destroyed": "Attachment destroyed",
      "attachment_failed": "Failed to attach %{attachment_class}",
      "cancel": "Cancel",
      "choose_a_country": "Choose a country",
      "choose_an_option": "Choose an option",
      "choose_item": "Choose %{item}",
      "clear_value": "Clear value",
      "click_to_reveal_filters": "Click to reveal filters",
      "close_modal": "Close modal",
      "confirm": "Confirm",
      "create_new_item": "Create new %{item}",
      "dashboard": "Dashboard",
      "dashboards": "Dashboards",
      "default_scope": "All",
      "delete": "delete",
      "delete_file": "Delete file",
      "delete_item": "Delete %{item}",
      "detach_item": "detach %{item}",
      "details": "details",
      "download": "Download",
      "download_file": "Download file",
      "download_item": "Download %{item}",
      "edit": "edit",
      "edit_item": "edit %{item}",
      "empty_dashboard_message": "Add cards to this dashboard",
      "failed": "Failed",
      "failed_to_find_attachment": "Failed to find attachment",
      "failed_to_load": "Failed to load",
      "file": {
        "one": "file",
        "other": "files"
      },
      "filter_by": "Filter by",
      "filters": "Filters",
      "go_back": "Go back",
      "grid_view": "Grid view",
      "hide_content": "Hide content",
      "home": "Home",
      "key_value_field": {
        "add_row": "Add row",
        "delete_row": "Delete row",
        "key": "Key",
        "reorder_row": "Reorder row",
        "value": "Value"
      },
      "list_is_empty": "List is empty",
      "loading": "Loading",
      "more": "More",
      "new": "new",
      "next_page": "Next page",
      "no_cancel": "No, cancel",
      "no_cards_present": "No cards present",
      "no_item_found": "No record found",
      "no_options_available": "No options available",
      "no_related_item_found": "No related record found",
      "not_authorized": "You are not authorized to perform this action.",
      "number_of_items": {
        "one": "one %{item}",
        "other": "%{count} %{item}",
        "zero": "no %{item}"
      },
      "oops_nothing_found": "Oops! Nothing found...",
      "order": {
        "higher": "Move record higher",
        "lower": "Move record lower",
        "reorder_record": "Reorder record",
        "to_bottom": "Move record to bottom",
        "to_top": "Move record to top"
      },
      "per_page": "Per page",
      "prev_page": "Previous page",
      "records_selected_from_all_pages_html": "All records selected from all pages",
      "remove_selection": "Remove selection",
      "reset": "reset",
      "reset_filters": "Reset filters",
      "resource_created": "Record created",
      "resource_destroyed": "Record destroyed",
      "resource_updated": "Record updated",
      "resources": "Resources",
      "run": "Run",
      "save": "Save",
      "search": {
        "cancel_button": "Cancel",
        "placeholder": "Search"
      },
      "select_all": "Select all",
      "select_all_matching": "Select all matching",
      "select_item": "Select item",
      "show_content": "Show content",
      "sign_out": "Sign out",
      "switch_to_view": "Switch to %{view_type} view",
      "table_view": "Table view",
      "this_field_has_attachments_disabled": "This field has attachments disabled.",
      "tools": "Tools",
      "type_to_search": "Type to search.",
      "unauthorized": "Unauthorized",
      "undo": "undo",
      "view": "View",
      "view_item": "view %{item}",
      "was_successfully_created": "was successfully created",
      "was_successfully_updated": "was successfully updated",
      "x_items_more": {
        "one": "one more item",
        "other": "%{count} more items",
        "zero": "no more items"
      },
      "x_records_selected_from_a_total_of_x_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> records selected on this page from a total of <span class=\"font-bold text-gray-700\">%{count}</span>",
      "x_records_selected_from_all_pages_html": "<span class=\"font-bold text-gray-700\">%{count}</span> records selected from all pages",
      "x_records_selected_from_page_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> records selected on this page",
      "yes_confirm": "Yes, I'm sure",
      "you_cant_upload_new_resource": "You can't upload files into the Trix editor until you save the resource.",
      "you_havent_set_attachment_key": "You haven't set an `attachment_key` to this Trix field.",
      "you_missed_something_check_form": "You might have missed something. Please check the form."
    },
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "short": {
          "about_x_hours": {
            "one": "~%{count}h",
            "other": "~%{count}h"
          },
          "about_x_months": {
            "one": "~%{count}mo",
            "other": "~%{count}mo"
          },
          "about_x_years": {
            "one": "~%{count}y",
            "other": "~%{count}y"
          },
          "almost_x_years": {
            "one": "~%{count}y",
            "other": "~%{count}y"
          },
          "half_a_minute": ".5m",
          "less_than_x_minutes": {
            "one": "<1m",
            "other": "<%{count}m"
          },
          "less_than_x_seconds": {
            "one": "<%{count}s",
            "other": "<%{count}s"
          },
          "over_x_years": {
            "one": ">%{count}y",
            "other": ">%{count}y"
          },
          "x_days": {
            "one": "%{count}d",
            "other": "%{count}d"
          },
          "x_minutes": {
            "one": "%{count}m",
            "other": "%{count}m"
          },
          "x_months": {
            "one": "%{count}mo",
            "other": "%{count}mo"
          },
          "x_seconds": {
            "one": "%{count}s",
            "other": "%{count}s"
          },
          "x_years": {
            "one": "%{count}y",
            "other": "%{count}y"
          }
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      }
    },
    "devise": {
      "confirmations": {
        "confirmed": "Your email address has been successfully confirmed.",
        "new": {
          "resend_confirmation_instructions": "Resend confirmation instructions"
        },
        "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
      },
      "failure": {
        "already_authenticated": "You are already signed in.",
        "expired": "Your account has expired due to inactivity. Please contact the site administrator.",
        "inactive": "Your account is not activated yet.",
        "invalid": "Invalid %{authentication_keys} or password.",
        "last_attempt": "You have one more attempt before your account is locked.",
        "locked": "Your account is locked.",
        "not_found_in_database": "Invalid %{authentication_keys} or password.",
        "session_limited": "Your login credentials were used in another browser. Please sign in again to continue in this browser.",
        "timeout": "Your session expired. Please sign in again to continue.",
        "unauthenticated": "You need to sign in or sign up before continuing.",
        "unconfirmed": "You have to confirm your email address before continuing."
      },
      "invalid_captcha": "The captcha input was invalid.",
      "invalid_security_question": "The security question answer was invalid.",
      "mailer": {
        "confirmation_instructions": {
          "action": "Confirm my account",
          "greeting": "Welcome %{recipient}!",
          "instruction": "You can confirm your account email through the link below:",
          "subject": "Confirmation instructions"
        },
        "email_changed": {
          "greeting": "Hello %{recipient}!",
          "message": "We're contacting you to notify you that your email has been changed to %{email}.",
          "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
          "subject": "Email Changed"
        },
        "password_change": {
          "greeting": "Hello %{recipient}!",
          "message": "We're contacting you to notify you that your password has been changed.",
          "subject": "Password Changed"
        },
        "reset_password_instructions": {
          "action": "Change my password",
          "greeting": "Hello %{recipient}!",
          "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
          "instruction_2": "If you didn't request this, please ignore this email.",
          "instruction_3": "Your password won't change until you access the link above and create a new one.",
          "subject": "Reset password instructions"
        },
        "unlock_instructions": {
          "action": "Unlock my account",
          "greeting": "Hello %{recipient}!",
          "instruction": "Click the link below to unlock your account:",
          "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
          "subject": "Unlock instructions"
        }
      },
      "omniauth_callbacks": {
        "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
        "success": "Successfully authenticated from %{kind} account."
      },
      "paranoid_verification_code": {
        "show": {
          "submit": "Submit",
          "submit_verification_code": "Submit verification code",
          "verification_code": "Verification code"
        },
        "updated": "Verification code accepted"
      },
      "paranoid_verify": {
        "code_required": "Please enter the code our support team provided"
      },
      "password_expired": {
        "change_required": "Your password is expired. Please renew your password.",
        "show": {
          "change_my_password": "Change my password",
          "current_password": "Current password",
          "new_password": "New password",
          "new_password_confirmation": "Confirm new password",
          "renew_your_password": "Renew your password"
        },
        "updated": "Your new password is saved."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Change my password",
          "change_your_password": "Change your password",
          "confirm_new_password": "Confirm new password",
          "new_password": "New password"
        },
        "new": {
          "forgot_your_password": "Forgot your password?",
          "send_me_reset_password_instructions": "Send me reset password instructions"
        },
        "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
        "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
        "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
        "updated": "Your password has been changed successfully. You are now signed in.",
        "updated_not_active": "Your password has been changed successfully."
      },
      "registrations": {
        "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
        "edit": {
          "are_you_sure": "Are you sure?",
          "cancel_my_account": "Cancel my account",
          "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
          "title": "Edit %{resource}",
          "unhappy": "Unhappy?",
          "update": "Update",
          "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
        },
        "new": {
          "sign_up": "Sign up"
        },
        "signed_up": "Welcome! You have signed up successfully.",
        "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
        "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
        "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
        "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
        "updated": "Your account has been updated successfully.",
        "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
      },
      "sessions": {
        "already_signed_out": "Signed out successfully.",
        "new": {
          "sign_in": "Log in"
        },
        "signed_in": "Signed in successfully.",
        "signed_out": "Signed out successfully."
      },
      "shared": {
        "links": {
          "back": "Back",
          "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
          "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
          "forgot_your_password": "Forgot your password?",
          "sign_in": "Log in",
          "sign_in_with_provider": "Sign in with %{provider}",
          "sign_up": "Sign up"
        },
        "minimum_password_length": {
          "one": "(%{count} character minimum)",
          "other": "(%{count} characters minimum)"
        }
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Resend unlock instructions"
        },
        "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
        "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
        "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
      }
    },
    "errors": {
      "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "must be accepted",
        "already_confirmed": "was already confirmed, please try signing in",
        "blank": "can't be blank",
        "confirmation": "doesn't match %{attribute}",
        "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
        "empty": "can't be empty",
        "equal_to": "must be equal to %{count}",
        "equal_to_current_password": "must be different than the current password.",
        "equal_to_email": "must be different than the email.",
        "even": "must be even",
        "exclusion": "is reserved",
        "expired": "has expired, please request a new one",
        "greater_than": "must be greater than %{count}",
        "greater_than_or_equal_to": "must be greater than or equal to %{count}",
        "in": "must be in %{count}",
        "inclusion": "is not included in the list",
        "invalid": "is invalid",
        "less_than": "must be less than %{count}",
        "less_than_or_equal_to": "must be less than or equal to %{count}",
        "model_invalid": "Validation failed: %{errors}",
        "not_a_number": "is not a number",
        "not_an_integer": "must be an integer",
        "not_found": "not found",
        "not_locked": "was not locked",
        "not_saved": {
          "one": "1 error prohibited this %{resource} from being saved:",
          "other": "%{count} errors prohibited this %{resource} from being saved:"
        },
        "odd": "must be odd",
        "other_than": "must be other than %{count}",
        "password_complexity": {
          "digit": {
            "one": "must contain at least one digit",
            "other": "must contain at least %{count} digits"
          },
          "lower": {
            "one": "must contain at least one lower-case letter",
            "other": "must contain at least %{count} lower-case letters"
          },
          "symbol": {
            "one": "must contain at least one punctuation mark or symbol",
            "other": "must contain at least %{count} punctuation marks or symbols"
          },
          "upper": {
            "one": "must contain at least one upper-case letter",
            "other": "must contain at least %{count} upper-case letters"
          }
        },
        "password_too_long": "is too long",
        "present": "must be blank",
        "required": "must exist",
        "taken": "has already been taken",
        "taken_in_past": "was used previously.",
        "too_long": {
          "one": "is too long (maximum is 1 character)",
          "other": "is too long (maximum is %{count} characters)"
        },
        "too_short": {
          "one": "is too short (minimum is 1 character)",
          "other": "is too short (minimum is %{count} characters)"
        },
        "wrong_length": {
          "one": "is the wrong length (should be 1 character)",
          "other": "is the wrong length (should be %{count} characters)"
        }
      },
      "unacceptable_request": "A supported version is expected in the Accept header.\n",
      "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
    },
    "flash": {
      "actions": {
        "create": {
          "notice": "%{resource_name} was successfully created."
        },
        "destroy": {
          "alert": "%{resource_name} could not be destroyed.",
          "notice": "%{resource_name} was successfully destroyed."
        },
        "update": {
          "notice": "%{resource_name} was successfully updated."
        }
      }
    },
    "hello": "Hello world",
    "helpers": {
      "select": {
        "prompt": "Please select"
      },
      "submit": {
        "create": "Create %{model}",
        "submit": "Save %{model}",
        "update": "Update %{model}"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ",",
          "format": "%u%n",
          "negative_format": "-%u%n",
          "precision": 2,
          "separator": ".",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "$"
        }
      },
      "format": {
        "delimiter": ",",
        "precision": 3,
        "round_mode": "default",
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Billion",
            "million": "Million",
            "quadrillion": "Quadrillion",
            "thousand": "Thousand",
            "trillion": "Trillion",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Bytes"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB",
            "zb": "ZB"
          }
        }
      },
      "nth": {
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "ransack": {
      "all": "all",
      "and": "and",
      "any": "any",
      "asc": "ascending",
      "attribute": "attribute",
      "combinator": "combinator",
      "condition": "condition",
      "desc": "descending",
      "or": "or",
      "predicate": "predicate",
      "predicates": {
        "blank": "is blank",
        "cont": "contains",
        "cont_all": "contains all",
        "cont_any": "contains any",
        "does_not_match": "doesn't match",
        "does_not_match_all": "doesn't match all",
        "does_not_match_any": "doesn't match any",
        "end": "ends with",
        "end_all": "ends with all",
        "end_any": "ends with any",
        "eq": "equals",
        "eq_all": "equals all",
        "eq_any": "equals any",
        "false": "is false",
        "gt": "greater than",
        "gt_all": "greater than all",
        "gt_any": "greater than any",
        "gteq": "greater than or equal to",
        "gteq_all": "greater than or equal to all",
        "gteq_any": "greater than or equal to any",
        "in": "in",
        "in_all": "in all",
        "in_any": "in any",
        "lt": "less than",
        "lt_all": "less than all",
        "lt_any": "less than any",
        "lteq": "less than or equal to",
        "lteq_all": "less than or equal to all",
        "lteq_any": "less than or equal to any",
        "matches": "matches",
        "matches_all": "matches all",
        "matches_any": "matches any",
        "not_cont": "doesn't contain",
        "not_cont_all": "doesn't contain all",
        "not_cont_any": "doesn't contain any",
        "not_end": "doesn't end with",
        "not_end_all": "doesn't end with all",
        "not_end_any": "doesn't end with any",
        "not_eq": "not equal to",
        "not_eq_all": "not equal to all",
        "not_eq_any": "not equal to any",
        "not_in": "not in",
        "not_in_all": "not in all",
        "not_in_any": "not in any",
        "not_null": "is not null",
        "not_start": "doesn't start with",
        "not_start_all": "doesn't start with all",
        "not_start_any": "doesn't start with any",
        "null": "is null",
        "present": "is present",
        "start": "starts with",
        "start_all": "starts with all",
        "start_any": "starts with any",
        "true": "is true"
      },
      "search": "search",
      "sort": "sort",
      "value": "value"
    },
    "simple_form": {
      "error_notification": {
        "default_message": "Please review the problems below:"
      },
      "no": "No",
      "required": {
        "mark": "*",
        "text": "required"
      },
      "yes": "Yes"
    },
    "support": {
      "array": {
        "last_word_connector": ", and ",
        "two_words_connector": " and ",
        "words_connector": ", "
      }
    },
    "time": {
      "am": "am",
      "formats": {
        "default": "%a, %d %b %Y %H:%M:%S %z",
        "long": "%B %d, %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    }
  },
  "it": {
    "1": "Sì",
    "2": false,
    "activerecord": {
      "attributes": {
        "infection": {
          "alert_aspe": "Aspergillus",
          "alert_burce": "BURKHOLDERIA CEPACIA COMPLEX",
          "alert_cand_aur": "Candida auris",
          "alert_candide": "CANDIDEMIE",
          "alert_cdi": "Clostridium difficile",
          "alert_legio": "Legionella PNEUMOPHILA",
          "area_sdo_richiedente_new": "Area",
          "descr_sdo_richiedente": "Reparto",
          "ica": "Tempo dal ricovero",
          "mat_gruppo": "Materiale",
          "mdr_acb": "ACINETOBATER",
          "mdr_cpe": "CPE",
          "mdr_cre": "CRE",
          "mdr_esbl": "ESBL",
          "mdr_gre": "GRE",
          "mdr_mrsa": "MRSA",
          "mdr_pa": "P. aeruginosa",
          "mdr_sp": "Streptococcus pneumonia",
          "mdr_stema": "STEMA",
          "mdr_vrsa_visa": "VRSA/VISA",
          "pres_descr": "Presidio"
        },
        "user": {
          "confirmation_sent_at": "Conferma inviata a",
          "confirmation_token": "Token di conferma",
          "confirmed_at": "Confermato il",
          "created_at": "Creato il",
          "current_password": "Password corrente",
          "current_sign_in_at": "Accesso corrente il",
          "current_sign_in_ip": "IP accesso corrente",
          "email": "Email",
          "encrypted_password": "Password criptata",
          "failed_attempts": "Tentativi falliti",
          "first_name": "Nome",
          "last_name": "Cognome",
          "last_sign_in_at": "Ultimo accesso il",
          "last_sign_in_ip": "Ultimo IP di accesso",
          "locked_at": "Bloccato il",
          "password": "Password",
          "password_confirmation": "Conferma password",
          "remember_created_at": "Ricordami creato il",
          "remember_me": "Ricordami",
          "reset_password_sent_at": "Reset password inviata a",
          "reset_password_token": "Token di reset password",
          "sign_in_count": "Numero di accessi",
          "unconfirmed_email": "Email non confermata",
          "unlock_token": "Token di sblocco",
          "updated_at": "Aggiornato il"
        }
      },
      "errors": {
        "messages": {
          "record_invalid": "Validazione fallita: %{errors}",
          "restrict_dependent_destroy": {
            "has_many": "Il record non può essere cancellato perchè esistono %{record} dipendenti",
            "has_one": "Il record non può essere cancellato perchè esiste un %{record} dipendente"
          }
        },
        "models": {
          "user": {
            "attributes": {
              "current_password": {
                "blank": "Inserire la password attuale"
              },
              "password": {
                "too_short": "La password deve contenere almeno 8 caratteri"
              },
              "password_confirmation": {
                "confirmation": "La password non corrisponde alla conferma"
              }
            }
          }
        }
      },
      "models": {
        "user": {
          "one": "Utente",
          "other": "Utenti"
        }
      }
    },
    "app": {
      "name": "Cruscotto Monitoraggio infezioni"
    },
    "avo": {
      "action_ran_successfully": "L'azione è stata eseguita con successo!",
      "actions": "Azioni",
      "and_x_other_resources": "e altri %{count} risorse",
      "applied": "applicato",
      "are_you_sure": "Sei sicuro?",
      "are_you_sure_detach_item": "Sei sicuro di voler staccare questo %{item}?",
      "are_you_sure_you_want_to_run_this_option": "Sei sicuro di voler eseguire questa azione?",
      "attach": "Allega",
      "attach_and_attach_another": "Allega e allega un altro",
      "attach_item": "Allega %{item}",
      "attachment_class_attached": "%{attachment_class} allegato.",
      "attachment_class_detached": "%{attachment_class} staccato.",
      "attachment_destroyed": "Allegato distrutto",
      "attachment_failed": "Impossibile allegare %{attachment_class}",
      "cancel": "Annulla",
      "choose_a_country": "Scegli un paese",
      "choose_an_option": "Scegli un'opzione",
      "choose_item": "Scegli %{item}",
      "clear_value": "Cancella valore",
      "click_to_reveal_filters": "Clicca per mostrare i filtri",
      "close_modal": "Chiudi modale",
      "confirm": "Conferma",
      "create_new_item": "Crea nuovo %{item}",
      "dashboard": "Cruscotto",
      "dashboards": "Cruscotti",
      "default_scope": "Tutti",
      "delete": "Elimina",
      "delete_file": "Elimina file",
      "delete_item": "Elimina %{item}",
      "detach_item": "Stacca %{item}",
      "details": "Dettagli",
      "download": "Scarica",
      "download_file": "Scarica file",
      "download_item": "Scarica %{item}",
      "edit": "Modifica",
      "edit_item": "Modifica %{item}",
      "empty_dashboard_message": "Aggiungi delle carte a questo cruscotto",
      "failed": "Fallito",
      "failed_to_find_attachment": "Impossibile trovare l'allegato",
      "failed_to_load": "Caricamento fallito",
      "file": {
        "one": "file",
        "other": "file"
      },
      "filter_by": "Filtra per",
      "filters": "Filtri",
      "go_back": "Torna indietro",
      "grid_view": "Vista griglia",
      "hide_content": "Nascondi contenuto",
      "home": "Home",
      "key_value_field": {
        "add_row": "Aggiungi riga",
        "delete_row": "Elimina riga",
        "key": "Chiave",
        "value": "Valore"
      },
      "list_is_empty": "La lista è vuota",
      "loading": "Caricamento in corso",
      "more": "Altro",
      "new": "nuovo",
      "next_page": "Pagina successiva",
      "no_cancel": "No, annulla",
      "no_cards_present": "Nessuna carta presente",
      "no_item_found": "Nessun record trovato",
      "no_options_available": "Nessuna opzione disponibile",
      "no_related_item_found": "Nessun record correlato trovato",
      "not_authorized": "Non sei autorizzato a eseguire questa azione.",
      "number_of_items": {
        "one": "un %{item}",
        "other": "%{count} %{item}",
        "zero": "nessun %{item}"
      },
      "oops_nothing_found": "Ops! Niente trovato...",
      "order": {
        "higher": "Sposta record più in alto",
        "lower": "Sposta record più in basso",
        "reorder_record": "Riordina record",
        "to_bottom": "Sposta record in fondo",
        "to_top": "Sposta record in cima"
      },
      "per_page": "Per pagina",
      "prev_page": "Pagina precedente",
      "records_selected_from_all_pages_html": "Tutti i record selezionati da tutte le pagine",
      "remove_selection": "Rimuovi selezione",
      "reset": "Reimposta",
      "reset_filters": "Reimposta filtri",
      "resource_created": "Record creato",
      "resource_destroyed": "Record distrutto",
      "resource_translations": {
        "product": {
          "save": "Salva il prodotto!"
        }
      },
      "resource_updated": "Record aggiornato",
      "resources": "Risorse",
      "run": "Esegui",
      "save": "Salva",
      "search": {
        "cancel_button": "Annulla",
        "placeholder": "Cerca"
      },
      "select_all": "Seleziona tutto",
      "select_all_matching": "Seleziona tutto corrispondente",
      "select_item": "Seleziona elemento",
      "show_content": "Mostra contenuto",
      "sign_out": "Esci",
      "switch_to_view": "Passa a vista %{view_type}",
      "table_view": "Vista tabella",
      "tools": "Strumenti",
      "type_to_search": "Digita per cercare.",
      "unauthorized": "Non autorizzato",
      "undo": "Annulla",
      "view": "Visualizza",
      "view_item": "Visualizza %{item}",
      "was_successfully_created": "è stato creato con successo",
      "was_successfully_updated": "è stato aggiornato con successo",
      "x_items_more": {
        "one": "un altro elemento",
        "other": "%{count} altri elementi",
        "zero": "nessun altro elemento"
      },
      "x_records_selected_from_a_total_of_x_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> record selezionati su questa pagina su un totale di <span class=\"font-bold text-gray-700\">%{count}</span>",
      "x_records_selected_from_all_pages_html": "<span class=\"font-bold text-gray-700\">%{count}</span> record selezionati da tutte le pagine",
      "x_records_selected_from_page_html": "<span class=\"font-bold text-gray-700\">%{selected}</span> record selezionati su questa pagina",
      "yes_confirm": "Sì, sono sicuro",
      "you_missed_something_check_form": "Potresti aver dimenticato qualcosa. Controlla il modulo."
    },
    "casi_per_organismo_select_filters": "Selezionare un presidio e un periodo",
    "charts": {
      "casi_per_area": "Casi per area",
      "casi_per_organismo": "N. MDR - ALERT",
      "ica_no": "<= 48 ore",
      "ica_si": "> 48 ore",
      "tassi": "Tassi per 1000 giornate di degenza",
      "tassi_strat_ica": "Tassi per 1000 giornate di degenza, stratificati per tempo di ricovero",
      "tassi_strat_mat": "Tassi per 1000 giornate di degenza, stratificati per materiale",
      "tassi_subtitle_line_area": " per l'area %{area}",
      "tassi_subtitle_line_ica": " ICA: %{ica}",
      "tassi_subtitle_line_reparto": " per i reparti %{reparto}",
      "tassi_subtitle_presidio_organismo": "Tassi di infezione per presidio %{presidio} e organismo %{organismo}",
      "total": "Totale"
    },
    "clear_all_filters": "Svuota tutti i filtri",
    "date": {
      "abbr_day_names": [
        "dom",
        "lun",
        "mar",
        "mer",
        "gio",
        "ven",
        "sab"
      ],
      "abbr_month_names": [
        null,
        "gen",
        "feb",
        "mar",
        "apr",
        "mag",
        "giu",
        "lug",
        "ago",
        "set",
        "ott",
        "nov",
        "dic"
      ],
      "day_names": [
        "domenica",
        "lunedì",
        "martedì",
        "mercoledì",
        "giovedì",
        "venerdì",
        "sabato"
      ],
      "formats": {
        "default": "%d/%m/%Y",
        "long": "%d %B %Y",
        "short": "%d %b"
      },
      "month_names": [
        null,
        "gennaio",
        "febbraio",
        "marzo",
        "aprile",
        "maggio",
        "giugno",
        "luglio",
        "agosto",
        "settembre",
        "ottobre",
        "novembre",
        "dicembre"
      ],
      "order": [
        "day",
        "month",
        "year"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "circa un'ora",
          "other": "circa %{count} ore"
        },
        "about_x_months": {
          "one": "circa un mese",
          "other": "circa %{count} mesi"
        },
        "about_x_years": {
          "one": "circa un anno",
          "other": "circa %{count} anni"
        },
        "almost_x_years": {
          "one": "quasi un anno",
          "other": "quasi %{count} anni"
        },
        "half_a_minute": "mezzo minuto",
        "less_than_x_minutes": {
          "one": "meno di un minuto",
          "other": "meno di %{count} minuti"
        },
        "less_than_x_seconds": {
          "one": "meno di un secondo",
          "other": "meno di %{count} secondi"
        },
        "over_x_years": {
          "one": "oltre un anno",
          "other": "oltre %{count} anni"
        },
        "x_days": {
          "one": "%{count} giorno",
          "other": "%{count} giorni"
        },
        "x_minutes": {
          "one": "%{count} minuto",
          "other": "%{count} minuti"
        },
        "x_months": {
          "one": "%{count} mese",
          "other": "%{count} mesi"
        },
        "x_seconds": {
          "one": "%{count} secondo",
          "other": "%{count} secondi"
        },
        "x_years": {
          "one": "%{count} anno",
          "other": "%{count} anni"
        }
      },
      "prompts": {
        "day": "Giorno",
        "hour": "Ora",
        "minute": "Minuto",
        "month": "Mese",
        "second": "Secondo",
        "year": "Anno"
      }
    },
    "devise": {
      "confirmations": {
        "confirmed": "Il tuo account è stato correttamente confermato.",
        "new": {
          "resend_confirmation_instructions": "Invia di nuovo le istruzioni per la conferma"
        },
        "send_instructions": "Riceverai un messaggio email con le istruzioni per confermare il tuo account entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email con le istruzioni per confermare il tuo account entro qualche minuto.",
        "show": {
          "account_activation": "Attivazione account",
          "activate": "Attiva il tuo account",
          "choose_a_password": "Scegli una password",
          "password_confirmation": "Conferma password",
          "password_hint": "La password deve contenere almeno 8 caratteri, una maiuscola e un numero"
        }
      },
      "failure": {
        "already_authenticated": "Hai già effettuato l'accesso.",
        "expired": "Il tuo account è stato bloccato per inattività. Contatta l amministratore del sito.",
        "inactive": "Il tuo account non è stato ancora attivato.",
        "invalid": "Credenziali %{authentication_keys} o password non valide.",
        "last_attempt": "Hai ancora un tentativo prima che l'account venga bloccato.",
        "locked": "Il tuo account è bloccato.",
        "not_found_in_database": "Credenziali %{authentication_keys} o password non valide.",
        "session_limited": "Hai effettuato l accesso in un altro browser. Fai nuovamente l accesso per connetterti in questo.",
        "timeout": "Sessione scaduta, accedere nuovamente per continuare.",
        "unauthenticated": "Devi accedere o registrarti per continuare.",
        "unconfirmed": "Devi confermare il tuo account per continuare."
      },
      "invalid_captcha": "Il captcha inserito non è valido!",
      "invalid_security_question": "La risposta alla domanda di sicurezza non è valida.",
      "mailer": {
        "confirmation_instructions": {
          "action": "Conferma il mio account",
          "greeting": "Benvenuto %{recipient}!",
          "instruction": "Puoi confermare il tuo account cliccando sul link qui sotto:",
          "subject": "Istruzioni per la conferma"
        },
        "email_changed": {
          "greeting": "Ciao %{recipient}!",
          "message": "Ti stiamo contattando per notificarti che la tua email è stata cambiata in %{email}.",
          "message_unconfirmed": null,
          "subject": "Email modificata"
        },
        "password_change": {
          "greeting": "Ciao %{recipient}!",
          "message": "Ti stiamo contattando per notificarti che la tua password è stata modificata.",
          "subject": "Password modificata"
        },
        "reset_password_instructions": {
          "action": "Cambia la mia password",
          "greeting": "Ciao %{recipient}!",
          "instruction": "Qualcuno ha richiesto di resettare la tua password, se lo vuoi davvero puoi farlo cliccando sul link qui sotto.",
          "instruction_2": "Se non sei stato tu ad effettuare questa richiesta puoi ignorare questa mail.",
          "instruction_3": "La tua password non cambierà finché non accederai al link sopra.",
          "subject": "Istruzioni per reimpostare la password"
        },
        "unlock_instructions": {
          "action": "Sblocca il mio account",
          "greeting": "Ciao %{recipient}!",
          "instruction": "Clicca sul link qui sotto per sbloccare il tuo account:",
          "message": "Il tuo account è stato bloccato a seguito di un numero eccessivo di tentativi di accesso falliti.",
          "subject": "Istruzioni per sbloccare l'account"
        }
      },
      "omniauth_callbacks": {
        "failure": "Non è stato possibile autorizzarti da %{kind} perché \"%{reason}\".",
        "success": "Autorizzato con successo dall'account %{kind}."
      },
      "paranoid_verification_code": {
        "show": {
          "submit": "Invia",
          "submit_verification_code": "Invia codice di verifica",
          "verification_code": "Codice di verifica"
        },
        "updated": "Codice di verifica accettato"
      },
      "paranoid_verify": {
        "code_required": "Inserisci il codice fornito dal nostro team di supporto"
      },
      "password_expired": {
        "change_required": "La tua password è scaduta. Si prega di rinnovarla!",
        "show": {
          "change_my_password": "Cambia la mia password",
          "current_password": "Password attuale",
          "new_password": "Nuova password",
          "new_password_confirmation": "Conferma nuova password",
          "renew_your_password": "Rinnova la tua password"
        },
        "updated": "La tua nuova password è stata salvata."
      },
      "passwords": {
        "edit": {
          "change_my_password": "Cambia la mia password",
          "change_your_password": "Cambia la tua password",
          "confirm_new_password": "Conferma la nuova password",
          "new_password": "Nuova password"
        },
        "new": {
          "forgot_your_password": "Password dimenticata?",
          "send_me_reset_password_instructions": "Inviami le istruzioni per resettare la password"
        },
        "no_token": "Puoi accedere a questa pagina solamente dalla email di reset della password. Se vieni dalla email controlla di aver inserito l'url completo riportato nella email.",
        "send_instructions": "Riceverai un messaggio email con le istruzioni per reimpostare la tua password entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email contentente un link per il ripristino della password",
        "updated": "La tua password è stata cambiata. Ora sei collegato.",
        "updated_not_active": "La tua password è stata cambiata."
      },
      "registrations": {
        "destroyed": "Arrivederci! L'account è stato cancellato. Speriamo di rivederti presto.",
        "edit": {
          "are_you_sure": "Sei sicuro?",
          "cancel_my_account": "Rimuovi il mio account",
          "currently_waiting_confirmation_for_email": "In attesa di conferma per: %{email}",
          "leave_blank_if_you_don_t_want_to_change_it": "lascia in bianco se non vuoi cambiarla",
          "title": "Modifica %{resource}",
          "unhappy": "Scontento",
          "update": "Aggiorna",
          "we_need_your_current_password_to_confirm_your_changes": "abbiamo bisogno della tua password attuale per confermare i cambiamenti"
        },
        "new": {
          "sign_up": "Registrati"
        },
        "signed_up": "Iscrizione eseguita correttamente. Se abilitata, una conferma è stata inviata al tuo indirizzo email.",
        "signed_up_but_inactive": "Iscrizione eseguita correttamente. Però non puoi accedere in quanto il tuo account non è ancora attivo.",
        "signed_up_but_locked": "Iscrizione eseguita correttamente. Però non puoi accedere in quanto il tuo account è bloccato.",
        "signed_up_but_unconfirmed": "Ti è stato inviato un messaggio con un link di conferma. Ti invitiamo a visitare il link per attivare il tuo account.",
        "update_needs_confirmation": "Il tuo account è stato aggiornato, ma dobbiamo verificare la tua email. Ti invitiamo a consultare la tua email e cliccare sul link di conferma.",
        "updated": "Il tuo account è stato aggiornato.",
        "updated_but_not_signed_in": "Il tuo account è stato aggiornato con successo, ma dato che la tua password è cambiata, devi accedere di nuovo."
      },
      "sessions": {
        "already_signed_out": "Sei uscito correttamente.",
        "new": {
          "sign_in": "Accedi"
        },
        "signed_in": "Accesso effettuato con successo.",
        "signed_out": "Sei uscito correttamente."
      },
      "shared": {
        "links": {
          "back": "Indietro",
          "didn_t_receive_confirmation_instructions": "Non hai ricevuto le istruzioni per la conferma?",
          "didn_t_receive_unlock_instructions": "Non hai ricevuto le istruzioni per lo sblocco?",
          "forgot_your_password": "Password dimenticata?",
          "sign_in": "Accedi",
          "sign_in_with_provider": "Accedi con %{provider}",
          "sign_out": "Esci",
          "sign_up": "Registrati",
          "your_account": "Il tuo account"
        },
        "minimum_password_length": {
          "one": "(minimo %{count} carattere)",
          "other": "(minimo %{count} caratteri)"
        }
      },
      "unlocks": {
        "new": {
          "resend_unlock_instructions": "Invia di nuovo le istruzioni per lo sblocco"
        },
        "send_instructions": "Riceverai un messaggio email con le istruzioni per sbloccare il tuo account entro qualche minuto.",
        "send_paranoid_instructions": "Se la tua email esiste nel nostro database, riceverai un messaggio email con le istruzioni per sbloccare il tuo account entro qualche minuto.",
        "unlocked": "Il tuo account è stato correttamente sbloccato. Ora sei collegato."
      }
    },
    "enumerize": {
      "infection": {
        "area_sdo_richiedente_new": {
          "chir gen": "Chirurgia Generale",
          "chir spec": "Chirurgia Specialistica",
          "int": "Terapie Intensive",
          "med gen": "Medicina Generale",
          "med spec": "Medicina Specialistica"
        },
        "ica": {
          "false": "<= 48 ore",
          "true": "> 48 ore"
        },
        "mat_gruppo": {
          "altri": "Altro",
          "essudati": "Essudati/Pus/Ferite",
          "gastro_ent": "Apparato gastro-enterico",
          "gen_uro": "Apparato genito-urinario",
          "resp": "Respiratori",
          "sangue_s_p_lcs": "Sangue, siero, plasma, liquor"
        },
        "organismo": {
          "alert_aspe": "Aspergillus",
          "alert_burce": "B. cepacia complex",
          "alert_cand_aur": "C. auris",
          "alert_candide": "Candidemie",
          "alert_cdi": "C. difficile",
          "alert_legio": "Legionella",
          "mdr_acb": "Acinetobacter",
          "mdr_cpe": "CPE",
          "mdr_cre": "CRE",
          "mdr_esbl": "ESBL",
          "mdr_gre": "GRE",
          "mdr_mrsa": "MRSA",
          "mdr_pa": "P. aeruginosa",
          "mdr_sp": "S. pneumoniae",
          "mdr_stema": "S. malthophilia",
          "mdr_vrsa_visa": "VRSA/VISA"
        },
        "pres_descr": {
          "cto": "CTO",
          "molinette": "Molinette",
          "oirm": "OIRM",
          "s. anna": "Sant'Anna"
        }
      }
    },
    "errors": {
      "format": "%{attribute} %{message}",
      "messages": {
        "accepted": "deve essere accettata",
        "already_confirmed": "è stato già confermato",
        "blank": "non può essere lasciato in bianco",
        "confirmation": "non coincide con %{attribute}",
        "confirmation_period_expired": "deve essere confermato entro %{period}, richiedi una nuova conferma",
        "empty": "non può essere vuoto",
        "equal_to": "deve essere uguale a %{count}",
        "equal_to_current_password": " deve essere differente dalla password corrente!",
        "equal_to_email": "deve essere differente dall'email",
        "even": "deve essere pari",
        "exclusion": "è riservato",
        "expired": "è scaduto, si prega di richiederne uno nuovo",
        "greater_than": "deve essere maggiore di %{count}",
        "greater_than_or_equal_to": "deve essere maggiore o uguale a %{count}",
        "inclusion": "non è compreso tra le opzioni disponibili",
        "invalid": "non è valido",
        "less_than": "deve essere minore di %{count}",
        "less_than_or_equal_to": "deve essere minore o uguale a %{count}",
        "model_invalid": "Validazione fallita: %{errors}",
        "not_a_number": "non è un numero",
        "not_an_integer": "non è un numero intero",
        "not_found": "non trovato",
        "not_locked": "non era bloccato",
        "not_saved": {
          "one": "Non posso salvare questo %{resource}: 1 errore",
          "other": "Non posso salvare questo %{resource}: %{count} errori."
        },
        "odd": "deve essere dispari",
        "other_than": "devono essere di numero diverso da %{count}",
        "password_complexity": {
          "digit": {
            "one": "deve contenere almeno una cifra",
            "other": "deve contenere almeno %{count} cifre"
          },
          "lower": {
            "one": "deve contenere almeno una lettera minuscola",
            "other": "deve contenere almeno %{count} lettere minuscole"
          },
          "symbol": {
            "one": "deve contenere almeno un simbolo di punteggiatura o un simbolo speciale",
            "other": "deve contenere almeno %{count} tra simboli di punteggiatura e simboli speciali"
          },
          "upper": {
            "one": "deve contenere almeno una lettera maiuscola",
            "other": "deve contenere almeno %{count} lettere maiuscole"
          }
        },
        "present": "deve essere lasciato in bianco",
        "required": "deve esistere",
        "taken": "è già presente",
        "taken_in_past": "è stata gia' utilizzata in passato!",
        "too_long": {
          "one": "è troppo lungo (il massimo è %{count} carattere)",
          "other": "è troppo lungo (il massimo è %{count} caratteri)"
        },
        "too_short": {
          "one": "è troppo corto (il minimo è %{count} carattere)",
          "other": "è troppo corto (il minimo è %{count} caratteri)"
        },
        "wrong_length": {
          "one": "è della lunghezza sbagliata (deve essere di %{count} carattere)",
          "other": "è della lunghezza sbagliata (deve essere di %{count} caratteri)"
        }
      },
      "template": {
        "body": "Ricontrolla i seguenti campi:",
        "header": {
          "one": "Non posso salvare questo %{model}: %{count} errore",
          "other": "Non posso salvare questo %{model}: %{count} errori."
        }
      }
    },
    "helpers": {
      "select": {
        "prompt": "Seleziona..."
      },
      "submit": {
        "create": "Crea %{model}",
        "submit": "Invia %{model}",
        "update": "Aggiorna %{model}"
      }
    },
    "number": {
      "currency": {
        "format": {
          "delimiter": ".",
          "format": "%n %u",
          "precision": 2,
          "separator": ",",
          "significant": false,
          "strip_insignificant_zeros": false,
          "unit": "€"
        }
      },
      "format": {
        "delimiter": ".",
        "precision": 2,
        "separator": ",",
        "significant": false,
        "strip_insignificant_zeros": false
      },
      "human": {
        "decimal_units": {
          "format": "%n %u",
          "units": {
            "billion": "Miliardi",
            "million": "Milioni",
            "quadrillion": "Biliardi",
            "thousand": "Mila",
            "trillion": "Bilioni",
            "unit": ""
          }
        },
        "format": {
          "delimiter": "",
          "precision": 3,
          "significant": true,
          "strip_insignificant_zeros": true
        },
        "storage_units": {
          "format": "%n %u",
          "units": {
            "byte": {
              "one": "Byte",
              "other": "Byte"
            },
            "eb": "EB",
            "gb": "GB",
            "kb": "KB",
            "mb": "MB",
            "pb": "PB",
            "tb": "TB"
          }
        }
      },
      "percentage": {
        "format": {
          "delimiter": "",
          "format": "%n%"
        }
      },
      "precision": {
        "format": {
          "delimiter": ""
        }
      }
    },
    "ransack": {
      "all": "tutti",
      "and": "e",
      "any": "qualsiasi",
      "asc": "crescente",
      "attribute": "attributo",
      "combinator": "combinatore",
      "condition": "condizione",
      "desc": "decrescente",
      "or": "o",
      "predicate": "predicato",
      "predicates": {
        "blank": "è vuoto",
        "cont": "contiene",
        "cont_all": "contiene tutti",
        "cont_any": "contiene almeno un",
        "does_not_match": "non corrisponde",
        "does_not_match_all": "non corrisponde con nessuno",
        "does_not_match_any": "non corrisponde ad uno qualsiasi",
        "end": "finisce con",
        "end_all": "finisce con tutti",
        "end_any": "finisce con almeno un",
        "eq": "uguale a",
        "eq_all": "uguale ad ognuno",
        "eq_any": "uguale ad almeno un",
        "false": "è falso",
        "gt": "maggiore di",
        "gt_all": "maggiore di tutti",
        "gt_any": "maggiore di almeno un",
        "gteq": "maggiore o uguale a",
        "gteq_all": "maggiore o uguale a tutti",
        "gteq_any": "maggiore o uguale ad almeno un",
        "in": "in",
        "in_all": "in tutti",
        "in_any": "in almeno un",
        "lt": "minore di",
        "lt_all": "minore di tutti",
        "lt_any": "minore di almeno un",
        "lteq": "minore o uguale a",
        "lteq_all": "minore o uguale a tutti",
        "lteq_any": "minore o uguale ad almeno un",
        "matches": "combacia con",
        "matches_all": "combacia con tutti",
        "matches_any": "combacia con almeno un",
        "not_cont": "non contiene",
        "not_cont_all": "non contiene nessuno",
        "not_cont_any": "non contiene un qualsiasi",
        "not_end": "non finisce con",
        "not_end_all": "non finisce con nessuno",
        "not_end_any": "non finisce con uno qualsiasi",
        "not_eq": "diverso da",
        "not_eq_all": "diverso da tutti",
        "not_eq_any": "diverso da uno qualsiasi",
        "not_in": "non in",
        "not_in_all": "non in tutti",
        "not_in_any": "non in almeno un",
        "not_null": "non è nullo",
        "not_start": "non inizia con",
        "not_start_all": "non inizia con nessuno",
        "not_start_any": "non inizia con uno qualsiasi",
        "null": "è nullo",
        "present": "è presente",
        "start": "inizia con",
        "start_all": "inizia con tutti",
        "start_any": "inizia con almeno un",
        "true": "è vero"
      },
      "search": "cerca",
      "sort": "ordinamento",
      "value": "valore"
    },
    "support": {
      "array": {
        "last_word_connector": " e ",
        "two_words_connector": " e ",
        "words_connector": ", "
      }
    },
    "tassi_select_filters": "Selezionare un presidio e un organismo",
    "time": {
      "am": "am",
      "formats": {
        "default": "%a %d %b %Y, %H:%M:%S %z",
        "long": "%d %B %Y %H:%M",
        "short": "%d %b %H:%M"
      },
      "pm": "pm"
    }
  }
}